import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_s = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - S'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>S</h2>
     <LatQuotesIntro />
    
     <p><b>Saepe creat molles aspera spina rosas</b> - Often the prickly thorn produces tender roses. (Ovid)</p>
<p><b>Saepe ne utile quidem est scire quid futurum sit</b> - Often it is not even advantageous to know what will be. (Cicero)</p>
<p><b>Saepe stilum vertas</b> - May you often turn the stylus (You should make frequent corrections.)</p>
<p><b>Salus populi suprema lex</b> - The safety of the people is the supreme law. (Cicero)</p>
<p><b>Salva veritate</b> - With truth preserved</p>
<p><b>Salve (plural salvete)</b> - Hail; welcome</p>
<p><b>Salve sis</b> - May you be well</p>
<p><b>Salve veritate</b> - Saving the truth</p>
<p><b>Salve</b> - Hello</p>
<p><b>Salve(te)</b> - Greetings!</p>
<p><b>Sanctum sanctorum</b> - The holy of holies</p>
<p><b>Sapere aude!</b> - Dare to be wise! (Horace)</p>
<p><b>Sapiens nihil affirmat quod non probat</b> - A wise man states as true nothing he does not prove (don't swear to anything you don't know firsthand)</p>
<p><b>Sartor resartus</b> - The tailor patched</p>
<p><b>Sat sapienti</b> - Enough for a wise man. (Plautus)</p>
<p><b>Satis</b> - Enough</p>
<p><b>Satius est impunitum relinqui facinus nocentis, quam innocentem damnari</b> - It is better that a crime is left unpunished than that an innocent man is punished. (Corpus Iuris Civilis)</p>
<p><b>Scala Caeli</b> - The ladder of heaven</p>
<p><b>Scala naturae</b> - The ladder of nature</p>
<p><b>Scandalum magnatum</b> - Scandal of magnates</p>
<p><b>Schola cantorum</b> - School of singers</p>
<p><b>Scientia est potentia</b> - Knowledge is power</p>
<p><b>Scientia non habet inimicum nisp ignorantem</b> - Science has no enemies but the ignorants</p>
<p><b>Scilicet (sc.)</b> - That is to say</p>
<p><b>Scio cur summae inter se dissentiant! Numeris Romanis utor!</b> - I know why the numbers don't agree! I use Roman numerals!</p>
<p><b>Scio me nihil scire</b> - I know that I know nothing. Certain knowledge cannot be obtained. (Socrates)</p>
<p><b>Scire tuum nihil est, nisi te scire hoc sciat alter</b> - Your knowledge is nothing when no one else knows that you know it</p>
<p><b>Sciri facias</b> - Cause (him) to know</p>
<p><b>Scito te ipsum</b> - Know yourself</p>
<p><b>Scribere est agere</b> - To write is to act</p>
<p><b>Scripsit</b> - He/she wrote it</p>
<p><b>Sculpsit</b> - He/she engraved it</p>
<p><b>Sed quis custodiet ipsos custodes?</b> - Who watches the watchmen? (Juvenal)</p>
<p><b>Sedit qui timuit ne non succederet</b> - He who feared he would not succeed sat still. (For fear of failure, he did nothing.) (Horace)</p>
<p><b>Semper fidelis</b> - Always faithful</p>
<p><b>Semper idem</b> - Always the same thing. (Cicero)</p>
<p><b>Semper inops quicumque cupit</b> - Whoever desires is always poor. (Claudian)</p>
<p><b>Semper letteris mandate</b> - Always get it in writing!</p>
<p><b>Semper paratus</b> - Always prepared</p>
<p><b>Semper superne nitens</b> - Always striving upwards</p>
<p><b>Semper ubi sub ubi ubique</b> - Always wear underwear everywhere</p>
<p><b>Senatus Populusque Romanus (SPQR)</b> - The Senate and the Roman people</p>
<p><b>Sensu lato</b> - Broadly speaking</p>
<p><b>Sensu stricto</b> - Strictly speaking</p>
<p><b>Sensu stricto, nullo metro compositum est</b> - Strictly speaking, it doesn't rhyme</p>
<p><b>Sentio aliquos togatos contra me conspirare</b> - I think some people in togas are plotting against me</p>
<p><b>Sequens (seq.)</b> - The following (one)</p>
<p><b>Sequens mirabitur aetas</b> - The following age will be amazed</p>
<p><b>Sequentia (seqq.)</b> - The following (ones)</p>
<p><b>Seriatim</b> - One after another in order</p>
<p><b>Serva me, servabo te</b> - Save me and I will save you. (Petronius Arbiter)</p>
<p><b>Si Deus pro nobis quis contra nos</b> - If God is with us who is against us</p>
<p><b>Si finis bonus est, totum bonum erit</b> - If the end is good, everything will be good (all's well that ends well)</p>
<p><b>Si monumentum requiris circumspice</b> - If you seek a monument, look around</p>
<p><b>Si post fata venit gloria non propero</b> - If glory comes after death, I'm not in a hurry (if one must die to be recognised, I can wait)</p>
<p><b>Si sapis, sis apis</b> - If you are wise, be a bee</p>
<p><b>Si tacuisses, philosophus manisses</b> - If you had kept quiet, you would have remained a philosopher. (Boethius)</p>
<p><b>Si tu id aeficas, ei venient. Ager somnia</b> - If you build it, they will come</p>
<p><b>Si vis amari, ama</b> - If you wish to be loved, love. (Seneca)</p>
<p><b>Si vis pacem, para bellum</b> - If you want peace, prepare for the war. (Vegetius)</p>
<p><b>Sic ad nauseam</b> - And so on to the point of causing nausea</p>
<p><b>Sic erat in fatis</b> - So it was fated</p>
<p><b>Sic faciunt omnes</b> - Everyone is doing it</p>
<p><b>Sic friatur crustum dulce</b> - That's the way the cookie crumbles</p>
<p><b>Sic itur ad astra</b> - Such is the path to the stars (i.e. Gain reputation) (Vergil)</p>
<p><b>Sic passim</b> - Thus everywhere</p>
<p><b>Sic semper tyrannis</b> - Thus always to tyrants</p>
<p><b>Sic transit gloria mundi</b> - So passes the glory of the world</p>
<p><b>Sic volo, sic iubeo</b> - I want this, I order this. (Juvenalis)</p>
<p><b>Sic</b> - Thus, just so</p>
<p><b>Silent enim leges inter arma</b> - Laws are silent in times of war. (Cicero)</p>
<p><b>Simia quam similis, turpissimus bestia, nobis!</b> - How like us is that very ugly beast the monkey. (Cicero)</p>
<p><b>Simplex munditiis</b> - Unaffected by manners. (Horace)</p>
<p><b>Simpliciter</b> - Naturally; without qualification</p>
<p><b>Sine cura</b> - Without a care</p>
<p><b>Sine die</b> - Without a day (indefinitely)</p>
<p><b>Sine ira et studio</b> - Without anger or bias. (Tacitus)</p>
<p><b>Sine loco (sl)</b> - Without place</p>
<p><b>Sine nobilitatis</b> - Without nobility (SNOB)</p>
<p><b>Sine prole (sp)</b> - Without a descendant</p>
<p><b>Sine qua non</b> - Something/someone indispensable</p>
<p><b>Sine sole sileo</b> - Without the sun I'm silent. (sundial inscription)</p>
<p><b>Siste, viator</b> - Wait, traveler</p>
<p><b>Sit tibi terra levis</b> - May the earth be light upon you</p>
<p><b>Sobria inebrietas</b> - Sober intoxication</p>
<p><b>Sol omnibus lucet</b> - The sun shines upon us all. (Petronius)</p>
<p><b>Solitudinem fecerunt, pacem appelunt</b> - They made a desert and called it peace. (Tacitus)</p>
<p><b>Sotto voce</b> - In soft voice</p>
<p><b>Spectatum veniunt, veniunt spectentur ut ipsae</b> - They come to see, they come that they themselves be seen 'to see and be seen (Ovid)</p>
<p><b>Spemque metumque inter dubiis</b> - Hover between hope and fear. (Vergil)</p>
<p><b>Spero melior</b> - I hope for better things</p>
<p><b>Spiritus asper</b> - Rough breathing</p>
<p><b>Spiritus lenis</b> - Smooth breathing</p>
<p><b>Splendide mendax</b> - Splendidly false. (Horace)</p>
<p><b>Splendor sine occasu</b> - Splendour without end</p>
<p><b>Stabat Mater</b> - The mother was standing</p>
<p><b>Stare decisis</b> - To stand by things decided</p>
<p><b>Status quo</b> - The current state of being</p>
<p><b>Stet</b> - Let it stand</p>
<p><b>Struit insidias lacrimis cum femina plorat</b> - When a woman weeps, she is setting traps with her tears. (Dionysius Cato)</p>
<p><b>Studium discendi voluntate quae cogi non potest constat</b> - Study depends on the good will of the student, a quality which cannot be secured by compulsion</p>
<p><b>Stultior stulto fuisti, qui tabellis crederes!</b> - Idiot of idiots, to trust what is written!</p>
<p><b>Stultorum calami carbones moenia chartae</b> - Chalk is the pen of fools, walls (their) paper No Graffiti please. Showing that graffiti is nothing new</p>
<p><b>Stultorum infinitus est numerus</b> - Infinite is the number of fools. (Bible)</p>
<p><b>Stultum est timere quod vitare non potes</b> - It is foolish to fear that which you cannot avoid. (Publilius Syrus)</p>
<p><b>Sua cuique voluptas</b> - Everyone has his own pleasures</p>
<p><b>Sub dio</b> - Under the open sky</p>
<p><b>Sub iudice</b> - Under a judge</p>
<p><b>Sub lite</b> - In dispute</p>
<p><b>Sub poena</b> - Under penalty of law</p>
<p><b>Sub rosa</b> - Under the rose. Secretly or in confidence</p>
<p><b>Sub secreto</b> - In secret</p>
<p><b>Sub silentio</b> - In silence</p>
<p><b>Sub sole nihil novi est</b> - There's nothing new under the sun</p>
<p><b>Sub voce (sv)</b> - Under the voice</p>
<p><b>Suggestio falsi</b> - Suggestion of something false</p>
<p><b>Suggestio veri, suggestio falsi</b> - An intimation of truth, an intimation of falcity</p>
<p><b>Sui generis</b> - Of his/her/its kind</p>
<p><b>Sui iuris</b> - Of one's own right</p>
<p><b>Sum, ergo edo</b> - I am, therefore I eat</p>
<p><b>Summa cum laude</b> - With highest honor</p>
<p><b>Summam scrutemur</b> - Let's look at the bottom line</p>
<p><b>Summum bonum</b> - The highest good</p>
<p><b>Summum ius, summa iniuria</b> - The extreme law is the greatest injustice. (Cicero)</p>
<p><b>Sumptus censum ne superet</b> - Let not your spending exceed your income (live within your means)</p>
<p><b>Sunt lacrimae rerum et mentem mortalia tangunt</b> - These are the tears of things, and our mortality cuts to the heart. (Vergil)</p>
<p><b>Sunt pueri pueri, puerilia tractant</b> - Children are children, (therefore) children do childish things</p>
<p><b>Suo iure</b> - In one's own right</p>
<p><b>Suo jure</b> - In one's rightful place</p>
<p><b>Suos cuique mos</b> - Everyone has his customs. (Gellius)</p>
<p><b>Supra</b> - Above or on an earlier page</p>
<p><b>Sursum corda</b> - Lift up your hearts (to God)</p>
<p><b>Suum cuique pulchrum est</b> - To each his own is beautiful. (Cicero)</p>

   </Layout>
  )
}

export default LatQuotes_s
